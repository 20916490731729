import React from 'react'
import { images } from '../../constants'
import {HiMenuAlt4, HiX} from 'react-icons/hi'
import { useState } from 'react'
import {motion} from 'framer-motion'
import './Navbar.scss'

const Navbar = () => {

  const [toggle, setToggle] = useState(false)

  return (
    <nav className='app__navbar'>
      {/* <div className='app__navbar-logo'>
        <img src={images.logo_final} alt="logoBigger" />
      </div> */}
      <ul className='app__navbar-links'>
        {['intro','about','work', 'skills', 'contact'].map((item)=>(
          <li className = 'app__flex p-text'key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
      </ul>
      
    </nav>
  )
}

export default Navbar